import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCaptainUpUser, setCaptainUpSetup } from '../store/reducers/captainUp';
import getRouterLocale from '../utils/getRouterLocale';

export const useCaptainUp = () => {
    const { isAuth, information } = useSelector((state) => state.user);
    const { signed_user, captainUpKey, captainUpToken, setupCaptainUp } = useSelector((state) => state.captainUp);
    const router = useRouter();
    const dispatch = useDispatch();
    const { shortLocale } = getRouterLocale(router.locale);
    const tr = useSelector((state) => state.global.data.translations);
    const listOfAvailablePlayers = tr['captain_up_available_player_list'];
    const isCaptainUpAvailable = listOfAvailablePlayers && listOfAvailablePlayers.includes(`${information.id}`);
    const theme = {
        colors: {
            primary: '#916D46',
            secondary: '#F7F1E1',
            bodyBackground: '#ffffff',
            cardBackground: '#F7F1E1',
            cardMenuBackground: '#F7F1E1',
            menuBackground: '#DBD2BC',
            progressBarBackground: '#DBD2BC',
            progressBarFill: '#916D46',
            footerBackground: '#F7F1E1',
            button: '#D73210',
            buttonHover: '#916D46',
            buttonInactive: '#D73210',
            buttonText: '#FFFFFF',
            buttonHoverText: '#F7F1E1',
            buttonInactiveText: '#000000',
            headingText: '#916D46',
            contentText: '#916D46',
            footerText: '#916D46',
            currencyText: '#916D46',
            selectedMenuText: '#916D46',
            selectedItemBackground: '#919191',
            informationPopupBackground: '#FFFFFF',
            backdropColor: '#000000',
            backdropOpacity: '0.5',
            notificationPopupHeaderBackground: '#363a42',
            notificationPopupHeaderText: '#FFFFFF',
            notificationPopupBodyBackground: '#272d33',
            notificationPopupBodyText: '#FFFFFF',
            notificationPopupTitleText: '#916D46',
            notificationPopupCloseIcon: '#916D46',
            notificationPopupButtonBackground: '#F7F1E1',
            notificationPopupButtonText: '#916D46',
            notificationPopupButtonHoverBackground: '#916d46',
            notificationPopupButtonHoverText: '#ffffff',
            sidebarBorderColor: '#916d46',
            sidebarBorderWidth: '1px',
            buttonBorderRadius: '50px',
            scrollBar: '#916d46',
            scrollBarBackground: '#dbd2bc'
        }
    };

    useEffect(() => {
        if (isAuth && information.id && window && window._CaptainUpWidget && !setupCaptainUp && isCaptainUpAvailable) {
            if (signed_user) {
                window._CaptainUpWidget.setTheme(theme);
                window._CaptainUpWidget.setLocaleLanguage(shortLocale);
                window._CaptainUpWidget.setPopupSizeDesktop('large');
                window._CaptainUpWidget.setPopupSizeMobile('large');
                window._CaptainUpWidget.enableGoal(true);
                window._CaptainUpWidget.setAuthDetails(captainUpKey, captainUpToken);
                window._CaptainUpWidget.setSignedUser(`${signed_user}`);
                window._CaptainUpWidget.login(`${information.id}`, `${information.firstName}`);
                dispatch(setCaptainUpSetup(true));
            } else {
                dispatch(getCaptainUpUser());
            }
        }
    }, [isAuth, information.id, signed_user, isCaptainUpAvailable]);

    return null;
};
