import { Fragment, lazy, Suspense, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loader from './Loader';
import GeneralErrorModal from './common/GeneralErrorModal';
import { useCaptainUp } from '../hooks/useCaptainUp';

const StopSessionModal = lazy(() => import('./common/StopSessionModal'));
const RealityCheckModal = lazy(() => import('./common/RealityCheckModal'));
const SuspendedModal = lazy(() => import('./common/SuspendedModal'));
const BrowserNotSupportedModal = lazy(() => import('./common/BrowserNotSupportedModal'));
const WelcomeModal = lazy(() => import('./common/WelcomeModal'));
const PromotionLoginModal = lazy(() => import('./common/promotion-login-modal'));
const CancelPendingWithdrawalSuccessModal = lazy(
    () => import('./common/wallet/fragments/CancelPendingWithdrawalSuccessModal')
);
const CancelPendingWithdrawalErrorModal = lazy(() => import('./common/wallet/fragments/CancelPendingWithdrawalErrorModal'));
const ChangePasswordSuccessModal = lazy(() => import('./common/profile/change-password/ChangePasswordSuccessModal'));
const DesktopLayout = lazy(() => import('./desktop/DesktopLayout'));
const MobileLayout = lazy(() => import('./mobile/MobileLayout'));

const Layout = ({ children, device = {}, isGamePage = false, alternateRefs }) => {
    const GlobalWrapper = device.isMobile ? MobileLayout : DesktopLayout;
    const { isAuth } = useSelector((state) => state.user);
    const { isModalStopSessionOpen, isOpenRealityCheckModal, loadingProgress } = useSelector((state) => state.global);
    const [pageLoaded, setPageLoaded] = useState(false);
    useCaptainUp(); // hide temporary until we have a JA lang in captain up

    useEffect(() => {
        // make loader full filling
        if (loadingProgress === 100) {
            const timer = setTimeout(() => setPageLoaded(true), 400);
            return () => clearTimeout(timer);
        } else setPageLoaded(false);
    }, [loadingProgress]);

    return (
        <Fragment>
            {!pageLoaded && <Loader loadingProgress={loadingProgress} alternateRefs={alternateRefs} />}
            {loadingProgress === 100 && (
                <GlobalWrapper isGamePage={isGamePage} device={device} isAuth={isAuth}>
                    {children}
                    <Suspense>
                        {isModalStopSessionOpen && <StopSessionModal isOpen={isModalStopSessionOpen} />}
                        {isOpenRealityCheckModal && <RealityCheckModal isOpen={isOpenRealityCheckModal} />}
                        <SuspendedModal />
                        <GeneralErrorModal />
                        <BrowserNotSupportedModal isOpen={!!device?.isIE} />
                        {isAuth && (
                            <Fragment>
                                <WelcomeModal />
                                <PromotionLoginModal />
                                <CancelPendingWithdrawalErrorModal />
                                <CancelPendingWithdrawalSuccessModal />
                            </Fragment>
                        )}
                        <ChangePasswordSuccessModal />
                    </Suspense>
                </GlobalWrapper>
            )}
        </Fragment>
    );
};

export default Layout;
